/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "assets/styles/icon-svg.scss";

body {
  margin: 0px;
  height: 100vh;
}

.colorTheme-primary {
  background: var(--linear-gradient-primary);
  color: white;
}

.colorTheme-primary--border {
  border-color: var(--linear-gradient-primary);
}

.bg-theme__main-content {
  background-color: #edefff;
}

.bg-theme--primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}

.bg-theme--primary:hover {
  background-color: var(--primary-color-hover);
  border-color: var(--primary-color-hover);
}

.divider__text--color-theme {
  color: #1aa6f1;
}

.card-shadow {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
}

.p-inputtext {
  color: #40474f;
}

.p-inputtext__border--custom {
  border-radius: 14px;
}

.p-variant-filled {
  background-color: #e8ebed;
}

.top-72 {
  top: 72%;
}

.top-59 {
  top: 59% !important;
}

.top-58 {
  top: 58% !important;
}

p.horizontal-line-custom {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #d2d6db;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

p.horizontal-line-custom span {
  background: #fff;
  padding: 0 10px;
}

.photo-image--fit {
  object-fit: cover;
}

.tag-id__text--small {
  font-size: 0.6rem;
  font-weight: 500;
}

.tag-id__text--xsmall {
  font-size: 0.5rem;
  font-weight: 500;
}

.p-text--ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.p-inputtext.ng-touched.ng-invalid {
  border-color: #f87171;
}

p-password.ng-invalid.ng-touched > .p-password > .p-inputtext {
  border-color: #f87171;
}

p-password.border-0 > .p-password > .p-inputtext {
  border-color: var(--surface-0) !important;
  box-shadow: none;
}

.icon-svg {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-size: cover;
  filter: var(--filter-color-primary);
  background-position: center;
  background-repeat: no-repeat;
}

.icon-input--custom {
  background-size: 20px 20px !important;
  top: 16%;
  left: 8px;
  filter: var(--filter-color-gray);
}

.icon-svg--color-secondary {
  filter: var(--filter-color-gray);
}

.icon-svg--color-black {
  filter: brightness(0) saturate(100%) invert(0%) sepia(5%) saturate(7500%)
    hue-rotate(228deg) brightness(98%) contrast(106%);
}

.icon-svg--size-default {
  background-size: 24px 24px !important;
}

.icon-svg--size-sm {
  background-size: 18px 18px !important;
}

.bg-button--success {
  background-color: #a4f4e7;
  color: #0b7b69;
}
.bg-button--success:hover {
  background-color: #a4f4e7bb;
}

.bg-button--danger {
  background-color: #e4626f;
  color: #8c1823;
}
.bg-button--danger:hover {
  background-color: #e4626fb9;
}

#login-register #register_cep .p-inputtext {
  padding-left: 2.5rem;
}

.p-button-xs {
  font-size: 0.75rem;
  padding: 0.25rem 0.4rem;
}

.p-inputtext-xs {
  padding: 0.2rem 0.45rem !important;
  font-size: 0.9rem !important;
}

.small-input-switch {
  .p-inputswitch {
    height: 1.2rem;
    width: 2.3rem;
    &.p-inputswitch-checked {
      .p-inputswitch-slider::before {
        transform: translateX(1rem);
      }
    }

    .p-inputswitch-slider::before {
      width: 0.9rem;
      height: 0.9rem;
      margin-top: -0.46rem;
    }
  }
}

.form-input-style--default {
  input::placeholder {
    font-weight: 400;
    font-size: 1rem;
    word-wrap: break-word;
  }

  .form-input-textarea {
    position: relative;
  }

  .icon-input-textarea--custom {
    background-size: 20px 20px !important;
    position: absolute;
    filter: var(--filter-color-gray);
  }

  .icon-input-textarea--position {
    top: 6px;
    left: 2%;
  }

  @media screen and (min-width: 768px) {
    .icon-input-textarea--position {
      top: 12px;
      left: 8px;
    }
  }

  p-inputnumber {
    width: 100% !important;
  }

  .icon--padding-left.p-inputtext {
    padding-left: 2.5rem;
  }

  .p-dropdown-label {
    padding: 0 !important;
  }

  @media screen and (max-width: 767px) {
    .p-card-body {
      padding: 0.5rem !important;
    }
  }
}
